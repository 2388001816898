import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import axiosInstance, { baseUrlSockets } from "../../AxiosInstance";
import Header from "../../components/header";
import {
  MenuButton,
  MultiSelectInput,
  TextInput,
} from "../../components/input/inputs";
import ScheduleModal from "../../components/modal/ScheduleModal";
import Modal from "../../components/modal/modal";
import main from "../../styles/main.module.css";
import styles from "../users/styles/list.module.css";

const INITIAL_GATE_FORM = {
  nombre: "",
  latitud: 0,
  longitud: 0,
  ip_address: "",
  api_key: "rkdfjqoiw",
  barrier_states: [],
  id: null,
};

export default function GateList() {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filters, setFilters] = useState([]);
  const [gateSystemForm, setGateSystemForm] = useState(INITIAL_GATE_FORM);
  const [gateProgrammingSystemForm, setGateProgrammingSystemForm] = useState(
    {},
  );
  const [itemMenuOpen, setItemMenuOpen] = useState({ idGate: null });
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [gates, setGates] = useState([]);
  const [updatedData, setupdatedData] = useState(null);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showManualControlModal, setShowManualControlModal] = useState(false);
  const [scheduledOpeningId, setScheduledOpeningId] = useState(null);
  const [manualControlId, setManualControlId] = useState(null);

  const handleCloseModal = () => {
    setShowScheduleModal(false);
  };

  const [changeBarrier, setChangeBarrier] = useState({
    gateSystem: {},
    barrier: {},
  });
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    const savedSidebarState = localStorage.getItem("isSidebarCollapsed");
    return savedSidebarState !== null ? JSON.parse(savedSidebarState) : false;
  });

  useEffect(() => {
    (async () => {
      const gateList = await getGateList();
      console.log("gate list", gateList);
      setGates(gateList);
    })();
  }, []);

  const getGateList = async () => {
    const response = await axiosInstance.get("gatesystem/");
    return response.data.results;
  };

  useEffect(() => {
    let newSocket = new WebSocket(baseUrlSockets);

    newSocket.onopen = function () {
      console.log("conexion establecida");
    };
    newSocket.onerror = function () {
      console.log("error en conexion, Intentando reconectar...");
      setTimeout(() => {
        newSocket = new WebSocket(baseUrlSockets);
      }, 1000);
    };
    newSocket.onmessage = (event) => {
      const receivedMessage = JSON.parse(event.data);
      if (receivedMessage.type === "gatesystem_updated") {
        setupdatedData(receivedMessage.data);
      }
    };

    newSocket.onclose = function () {
      console.log("La conexion ha sido cerrada");
    };

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (!updatedData) return;
    if (gates.length === 0) return;
    if (gates.some((gate) => gate.id === updatedData.id)) {
      const indice = gates.findIndex((gate) => gate.id === updatedData.id);
      if (indice !== -1) {
        let updatedGatesSystem = [...gates];
        updatedGatesSystem[indice] = updatedData;
        setGates(updatedGatesSystem);
      }
    }
  }, [updatedData]);

  const handleGateSystemForm = () => {
    const { id, ...rest } = gateSystemForm;
    if (!id) {
      axiosInstance
        .post("gatesystem/", rest)
        .then(() => getGateList())
        .then((gateList) => {
          setGates(gateList);
          setShowCreateModal(false);
          resetGateSystemForm();
        });
    } else {
      axiosInstance
        .put("gatesystem/" + id + "/", rest)
        .then(() => getGateList())
        .then((gateList) => {
          setGates(gateList);
          setShowCreateModal(false);
          resetGateSystemForm();
        });
    }
  };

  const handleItemMenu = (id) => {
    const prevValue = { ...itemMenuOpen };
    if (prevValue.idGate === id) {
      setItemMenuOpen({ idGate: null });
    } else {
      setItemMenuOpen({ idGate: id });
    }
  };

  const handleEditGate = (gate) => {
    setShowCreateModal(true);
    setGateSystemForm({
      nombre: gate.nombre,
      api_key: gate.api_key,
      ip_address: gate.ip_address,
      latitud: gate.latitud,
      longitud: gate.longitud,
      id: gate.id,
    });
  };

  const handleDeleteGate = (gate) => {
    setShowDeleteModal(true);
    setGateSystemForm({
      nombre: gate.nombre,
      api_key: gate.api_key,
      ip_address: gate.ip_address,
      latitud: gate.latitud,
      longitud: gate.longitud,
      id: gate.id,
    });
  };

  const handleSetFormGate = (values) => {
    setFilters(values);
  };

  const handleConfirmDelete = () => {
    const { id } = gateSystemForm;
    axiosInstance
      .delete("gatesystem/" + id + "/")
      .then(() => getGateList())
      .then((gateList) => {
        setGates(gateList);
        setShowDeleteModal(false);
        resetGateSystemForm();
      });
  };
  // const handleConfirmManualControl = () => {

  // };

  const resetGateSystemForm = () => {
    setGateSystemForm(INITIAL_GATE_FORM);
  };

  const handleCancelCreate = () => {
    resetGateSystemForm();
  };
  const handleCancelManualControl = () => {
    setManualControlId(null);
  };

  function handleChangeStatusBarrier(gateSystem, barrier) {
    setShowChangeStatusModal(true);
    setChangeBarrier({
      gateSystem: gateSystem,
      barrier: barrier,
    });
  }

  function handleChangeStatusBarrierConfirm() {
    let body = {
      gatesystem_id: changeBarrier.gateSystem.id,
    };
    body[`barrera_${changeBarrier.barrier.barrera_id}`] =
      !changeBarrier.barrier.estado;
    axiosInstance
      .post("manually_barrier_control/", body)
      .then(() => getGateList())
      .then((gateList) => {
        setShowChangeStatusModal(false);
        setGates(gateList);
      });
  }

  const handleSidebarToggle = (isCollapsed) => {
    setIsSidebarCollapsed(isCollapsed);
  };

  // Filtered gates based on selected filters
  const filteredGates =
    filters.length > 0
      ? gates.filter((gate) => filters.includes(gate.id))
      : gates;

  const handleEditGateProgramming = (gateProgramming) => {
    // console.log(gateProgramming)
    setScheduledOpeningId(gateProgramming.id);
    setShowScheduleModal(true);
  };

  function handleActivateManualControl(gate) {
    if (gate.manually_control) return;
    setManualControlId(gate.id);

    setShowManualControlModal(true);
  }

  return (
    <div>
      <Header current={0} onSidebarToggle={handleSidebarToggle} />

      {/* Creacion y Edicion de barrera */}
      <div
        className={`${main.mainContent} ${isSidebarCollapsed ? main.expanded : main.collapsed}`}
      >
        <Modal
          show={showCreateModal}
          setter={setShowCreateModal}
          name={`${gateSystemForm.id ? "Editar" : "Agregar"} Barrera`}
          buttonName={gateSystemForm.id ? "Editar" : "Agregar"}
          onClickCancel={handleCancelCreate}
          onClick={handleGateSystemForm}
        >
          <div>
            <div className={"d-flex flex-row align-items-center w-100"}>
              <div style={{ flexBasis: "15%" }}>Nombre</div>
              <div style={{ flexBasis: "85%" }}>
                <TextInput
                  form={gateSystemForm}
                  setForm={setGateSystemForm}
                  internalName={"nombre"}
                ></TextInput>
              </div>
            </div>
            <div className={"d-flex flex-row align-items-center w-100"}>
              <div style={{ flexBasis: "15%" }}>IP</div>
              <div style={{ flexBasis: "85%" }}>
                <TextInput
                  form={gateSystemForm}
                  setForm={setGateSystemForm}
                  internalName={"ip_address"}
                ></TextInput>
              </div>
            </div>
            <div className={"d-flex flex-row align-items-center w-100"}>
              <div style={{ flexBasis: "15%" }}>Latitud</div>
              <div style={{ flexBasis: "85%" }}>
                <TextInput
                  form={gateSystemForm}
                  setForm={setGateSystemForm}
                  internalName={"latitud"}
                ></TextInput>
              </div>
            </div>
            <div className={"d-flex flex-row align-items-center w-100"}>
              <div style={{ flexBasis: "15%" }}>Longitud</div>
              <div style={{ flexBasis: "85%" }}>
                <TextInput
                  form={gateSystemForm}
                  setForm={setGateSystemForm}
                  internalName={"longitud"}
                ></TextInput>
              </div>
            </div>
          </div>
        </Modal>

        {/* Eliminar barrera */}
        <Modal
          show={showDeleteModal}
          setter={setShowDeleteModal}
          name={"Eliminar Barrera"}
          buttonName={"Eliminar"}
          onClickCancel={handleCancelCreate}
          onClick={handleConfirmDelete}
        >
          <div>
            <div className={"d-flex flex-row align-items-center w-100"}>
              {`¿Está seguro que desea eliminar barrera ${gateSystemForm.nombre}?`}
            </div>
          </div>
        </Modal>

        {/* ControlManual */}
        {/* <Modal imgUrl={'/manual_mode_icon.svg'} show={showManualControlModal} setter={setShowManualControlModal} name={'Cambio a modo manual'} buttonName={'Aceptar'} onClickCancel={handleCancelManualControl} onClick={handleConfirmManualControl}>
                    <div>
                        <div className={"d-flex flex-row align-items-center w-100"}>
                            {`Se desactivarán todas las programaciones de esta barrera mientras usted esté en este modo. ¿Desea continuar?`}
                        </div>
                    </div>
                </Modal> */}

        {/* Cerrar y abrir barrera */}
        <Modal
          show={showChangeStatusModal}
          setter={setShowChangeStatusModal}
          name={
            changeBarrier.barrier.estado ? "Cerrar Barrera" : "Abrir Barrera"
          }
          buttonName={
            changeBarrier.barrier.estado ? "Cerrar Barrera" : "Abrir Barrera"
          }
          onClick={handleChangeStatusBarrierConfirm}
        >
          <div>
            <div className={"d-flex flex-row align-items-center w-100"}>
              {`¿Está seguro que desea ${changeBarrier.barrier.estado ? "CERRAR" : "ABRIR"} la barrera ${changeBarrier.barrier.barrera_id} de '${changeBarrier.gateSystem.nombre}'?`}
            </div>
          </div>
        </Modal>

        <div className={"bg-light vh-100 mt-3"}>
          <div className={`${styles.createContainer}`}>
            <div className={"d-flex align-items-center"}>
              <div className={`${styles.listTitle}`}>
                Lista de Sistemas de Barreras
              </div>
              <div
                className={"d-flex ps-2"}
                style={{ color: "#8699CB", fontWeight: "500" }}
              >
                total {filteredGates.length}
              </div>
            </div>

            <div>
              <button
                className={`btn ${main.mainButton}`}
                onClick={() => {
                  setShowCreateModal(true);
                }}
              >
                Agregar
              </button>
            </div>
          </div>

          <div
            className={`${main.filterItem}`}
            style={{ marginBottom: "20px" }}
          >
            <img src="/filter_icon.svg" alt="filtro" width="19px" />
            <div className={main.filterLabel}>Filtro</div>
            <div style={{ width: "200px" }}>
              <MultiSelectInput
                label={"Todas las barreras"}
                options={gates?.map((x) => ({ id: x.id, name: x.nombre }))}
                setForm={handleSetFormGate}
              />
            </div>
          </div>

          {/* Renderizar el modal si showScheduleModal es true */}
          {showScheduleModal && (
            <ScheduleModal
              idGate={scheduledOpeningId}
              onClose={handleCloseModal}
              onSave={() => {
                setShowScheduleModal(false);
              }}
            />
          )}
          <table className={`${main.listTable}`}>
            <thead>
              <tr>
                <th className={main.tableItemHeader}>Estado</th>
                <th className={main.tableItemHeader}>Nombre</th>
                <th className={main.tableItemHeader}>IP</th>
                <th className={main.tableItemHeader}>Zona</th>
                <th className={main.tableItemHeader}>Localización</th>
                <th className={main.tableItemHeader}>Tráfico CAEX</th>
                <th className={main.tableItemHeader}>Última conexión</th>
                <th className={main.tableItemHeader}>Modalidad</th>
                <th className={main.tableItemHeader}></th>
                <th className={main.tableItemHeader}></th>
              </tr>
            </thead>
            <tbody>
              {filteredGates.map((gate, index) => {
                const differenceInMinutes = dayjs().diff(
                  dayjs(gate.ultima_conexion),
                  "minute",
                );
                const isActive = differenceInMinutes <= 10;
                // console.log(gate)
                return (
                  <tr key={index}>
                    <td>
                      <div
                        className={
                          isActive
                            ? `${styles.stateBlock} ${styles.stateActive}`
                            : `${styles.stateBlock}`
                        }
                      ></div>
                    </td>
                    <td>{gate.nombre}</td>
                    <td>{gate.ip_address}</td>
                    <td>{gate.zona_nombre}</td>
                    <td className={"h-100"} style={{ width: "100px " }}>
                      <div className="d-flex justify-content-center">
                        <div
                          onClick={() => {
                            window.open(
                              `https://www.google.com/maps?q=${gate.latitud},${gate.longitud}`,
                              "_blank",
                            );
                          }}
                          className={
                            "d-flex justify-content-center align-items-center"
                          }
                          style={{
                            cursor: "pointer",
                            borderRadius: "6px",
                            width: "30px",
                            height: "30px",
                            backgroundColor: "#F1F7FD",
                            border: "1px solid #45A6FF",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faLocationPin}
                            color={"#45A6FF"}
                          />
                        </div>
                      </div>
                    </td>
                    <td>{gate.caex_traffic ? "En transito" : "No"}</td>
                    <td>
                      {gate.ultima_conexion !== null
                        ? dayjs(gate.ultima_conexion).format(
                            "DD/MM/YYYY hh:mm A",
                          )
                        : ""}
                    </td>
                    <td>
                      {gate.is_gatesystem && (
                        <div className="d-flex" style={{ gap: "20px" }}>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                height: "32px",
                                width: "20px",
                              }}
                            >
                              <img
                                src={
                                  gate.manually_control
                                    ? "/manually_open.svg"
                                    : "/not_manually_open.svg"
                                }
                                alt={
                                  gate.manually_control ? "Manual" : "No manual"
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <p style={{ fontWeight: "600", margin: "0px" }}>
                              Control Manual
                            </p>
                          </div>

                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                height: "32px",
                                width: "20px",
                              }}
                            >
                              <img
                                src={
                                  gate.schedule_open
                                    ? "/schedule.svg"
                                    : "/not_schedule.svg"
                                }
                                alt={
                                  gate.schedule_open
                                    ? "Programado"
                                    : "No Programado"
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() => handleEditGateProgramming(gate)}
                              />
                            </div>
                            <p style={{ fontWeight: "600", margin: "0px" }}>
                              Apertura Programada
                            </p>
                          </div>
                        </div>
                      )}
                    </td>

                    <td>
                      <div className="d-flex" style={{ gap: "20px" }}>
                      {
                      (Array.isArray(gate.barrier_states)
                        ? gate.barrier_states
                        : JSON.parse(gate.barrier_states || "[]"))
                        .sort((a, b) => a.barrera_id - b.barrera_id)
                        .map(({ barrera_id, estado }) => (
                          <div key={barrera_id}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                                height: "32px",
                                width: "20px",
                              }}
                            >
                              <img
                                src={estado ? "/gate_opened.svg" : "/gate_closed.svg"}
                                alt={estado ? "b. abierta" : "b. cerrada"}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleChangeStatusBarrier(gate, { barrera_id, estado });
                                }}
                              />
                            </div>
                            <p style={{ fontWeight: "600", margin: "0px" }}>{barrera_id}</p>
                          </div>
                        ))}

                      </div>
                    </td>
                    <td>
                      <div>
                        <MenuButton
                          onClick={() => {
                            handleItemMenu(gate.id);
                          }}
                        >
                          {itemMenuOpen.idGate === gate.id && (
                            <div className={`${styles.dropdown}`}>
                              <div
                                onClick={() => handleEditGate(gate)}
                                className={`${styles.dropdownItem}`}
                              >
                                Editar
                              </div>
                              <div
                                onClick={() => handleDeleteGate(gate)}
                                className={`${styles.dropdownItem}`}
                              >
                                Eliminar
                              </div>
                            </div>
                          )}
                        </MenuButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
